/** @jsx _radi.r **/
  /** @radi-listen _radi_listen **/

  import _radi from 'radi';
  const action = _radi.action;
  const subscribe = _radi.subscribe;
  const worker = _radi.worker;
  const _radi_listen = _radi.listen;

  import globals from '../helpers/globals';
import logo from '../../assets/img/logo-l-white.png';

  export default class Header extends _radi.Component {
    constructor(...args) {
      super(...args);
      this.state = {
  location: null,
};
      this.on = {};
    }

    

    view() {
        const component = this;
        return [
<header class={ component.state.location }>
  <div class="wrapper">
    <div id="logo">
      <Link to="/"><img src={ logo } alt=""/></Link>
    </div>
    <ul id="main-menu">
      <li>
        <Link to={ globals.docs } core={ true }>Docs</Link>
      </li>
      <li>
        <Link to={ globals.repl }>Try online</Link>
      </li>
      <li>
        <a href={ globals.slack } target="_blank">Slack</a>
      </li>
      <li>
        <a href={ globals.github } target="_blank">GitHub</a>
      </li>
    </ul>
  </div>
</header>];
      }

  };