import App from './app/App.radi';
import { r, mount } from 'radi';

import './assets/stylus/main.styl'

window.loadJS = function(url, fn){
  var scriptTag = document.createElement('script');
  scriptTag.src = url;

  scriptTag.onload = fn;
  scriptTag.onreadystatechange = fn;

  document.body.appendChild(scriptTag);
};

// var a = new App()

// console.log(a, a.render())

// var toDestroy = mount([
//   r('div', { id: 'hell' },
//     r(App),
//     r('h1', { style: 'font-size: 20px;' }, 'Hello World 1'),
//     r('h1', {}, 'Hello World 2'),
//     r('button', {onclick: () => { console.log('lel') }}, 'Hello World 2'),
//   )]
// , app);

var toDestroy = mount(r(App)
, app);

if (module.hot) {
  module.hot.accept();
  module.hot.dispose(() => {
    // Before restarting the app, we create a new root element and dispose the old one
    if (toDestroy) {
      for (var i = 0; i < toDestroy.length; i++) {
        toDestroy[i].destroy();
      }
    }
    var del = document.getElementById('app');
    del.parentNode.replaceChild(del.cloneNode(false), del);
  });
}
