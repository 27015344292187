/** @jsx _radi.r **/
  /** @radi-listen _radi_listen **/

  import _radi from 'radi';
  const action = _radi.action;
  const subscribe = _radi.subscribe;
  const worker = _radi.worker;
  const _radi_listen = _radi.listen;

  import RadiRouter from '../../radi-router'
import routes from './routes.js'

const { Router, Link } = _radi.plugin(RadiRouter, routes);
window.Link = Link;

{}

  export default class App extends _radi.Component {
    constructor(...args) {
      super(...args);
      this.state = {};
      this.on = {};
    }

    

    view() {
        const component = this;
        return [
<Router></Router>];
      }

  };