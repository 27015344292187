import globals from './helpers/globals';
// import docs from './helpers/docs';

export default {
  routes: {
    '/': {
      component: () => import('./pages/Index.radi'),
    },
    [globals.repl]: {
      component: () => import('./pages/Repl.radi'),
      children: {
        '/:code': {
          component: () => import('./pages/Repl.radi'),
        },
      },
    },
    [globals.docs]: {
      component: () => import('./pages/docs/Introduction.radi'),
      // children: docs.list.reduce((acc, value) => {
      //   return Object.assign(acc, {
      //     [value.link]: {
      //       component: () => import('./pages/docs/' + value.name + '.radi'),
      //     },
      //   });
      // }, {}),
      children: {
        '/installation': {
          component: () => import('./pages/docs/Installation.radi'),
        },
        '/hyperscript': {
          component: () => import('./pages/docs/Hyperscript.radi'),
        },
        '/components': {
          component: () => import('./pages/docs/Components.radi'),
        },
        '/state': {
          component: () => import('./pages/docs/State.radi'),
        },
        '/actions': {
          component: () => import('./pages/docs/Actions.radi'),
        },
        '/view': {
          component: () => import('./pages/docs/View.radi'),
        },
        '/listener': {
          component: () => import('./pages/docs/Listener.radi'),
        },
        '/events': {
          component: () => import('./pages/docs/Events.radi'),
        },
        '/headless-components': {
          component: () => import('./pages/docs/HeadlessComponents.radi'),
        },
        '/plugin': {
          component: () => import('./pages/docs/Plugin.radi'),
        },
        '/mount': {
          component: () => import('./pages/docs/Mount.radi'),
        },
      },
    },
  },
  // beforeEach(to, from, next) {
  //   if (to === '/restricted') {
  //     next(false)
  //   } else {
  //     next()
  //   }
  // },
  // afterEach(to, from) {
  //   console.log('This triggers after every route change', to, from)
  // },
}
