/** @jsx _radi.r **/
  /** @radi-listen _radi_listen **/

  import _radi from 'radi';
  const action = _radi.action;
  const subscribe = _radi.subscribe;
  const worker = _radi.worker;
  const _radi_listen = _radi.listen;

  import 'github-markdown-css'
import '../../assets/stylus/docs.styl';
import globals from '../helpers/globals';
import search from '../helpers/search';
import searchTerms from '../helpers/docs';
import Header from '../components/Header';

  export default class DocsLayout extends _radi.Component {
    constructor(...args) {
      super(...args);
      this.state = {
  searchOverlay: false,
  search: '',
  results: [],
};
      this.on = {
  mount() {
    document.onkeydown = e => this.trigger('keydown', e)
  },
  // @subscribe(document)
  keydown(e) {
    if ((e.metaKey === true || e.ctrlKey === true)
      && e.keyCode === 70) {
      e.preventDefault();
      this.openSearch();
      return false;
    } else {
      this.searchKey(e);
    }
  },
};
    }

    
searchKey(e) {
  if (e.keyCode === 27) {
    this.closeSearch()
  }
}

@action makeSearch(e) {
  let phrase = e.target.value

  if (phrase === '') {
    // this.noresults = phrase !== '' && this.results.length <= 0
    return { results: [] }
  }

  var treshold = 100
  var res = []
  for (var i = 0; i < searchTerms.length; i++) {
    for (var n = 0; n < searchTerms[i].p.length; n++) {
      var s = search(phrase, searchTerms[i].p[n])
      if (s >= treshold)
        res.push({
          term: searchTerms[i].p[n],
          score: s,
          page: searchTerms[i].page,
          title: searchTerms[i].title,
        })
    }
  }

  if (res.length <= 0) {
    return { results: [] }
  }

  return { results: res }
}

@action closeSearch() {
  return { searchOverlay: false }
}

@action openSearch() {
  return { searchOverlay: true }
}


addHighlight(text) {
  return text.replace(new RegExp(this.state.search.trim().replace(/  /g, ' ').split(' ').join('|'), 'ig'), (match) => (
      // '{' + match + '}'
      '<span class="mark">' + match + '</span>'
    ));
}

    view() {
        const component = this;
        return [
<template>
  <Header location="at-docs" />
  <div id="docs-menu">
    <div class="docs-logo">
      <Link to="/"><img src={ require('../../assets/img/llcolor.png') } alt=""/></Link>
    </div>
    <ul>
      <li class="search-btn-wrap">
        <button onclick={ e => this.openSearch(e) } class="search-btn">
          <i class="material-icons">&#xE8B6;</i>
          Search docs
        </button>
      </li>
      {/* <li>
        <strong>
          <i class="material-icons">&#xE869;</i>
          Getting started
        </strong>
      </li> */}
      <li>
        <Link to={ globals.docs }>Introduction</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/installation' }>Installation</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/hyperscript' }>Hyperscript</Link>
      </li>
      {/* <li>
        <strong>
          <i class="material-icons">&#xE8EB;</i>
          Components
        </strong>
      </li> */}
      <li>
        <Link to={ globals.docs + '/components' }>Components</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/state' }>State</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/actions' }>Actions</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/view' }>View</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/listener' }>Listener</Link>
      </li>
      {/* <li>
        <strong>
          <i class="material-icons">&#xE8BF;</i>
          Events
        </strong>
      </li> */}
      <li>
        <Link to={ globals.docs + '/events' }>Events</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/headless-components' }>Headless Components</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/plugin' }>Plugin</Link>
      </li>
      <li>
        <Link to={ globals.docs + '/mount' }>Mount</Link>
      </li>
      <hr/>
      <li>
        <Link to={ globals.repl }>Try online</Link>
      </li>
      <li>
        <a href={ globals.slack } target="_blank">Slack</a>
      </li>
      <li>
        <a href={ globals.github } target="_blank">GitHub</a>
      </li>
    </ul>
  </div>
  <div class="docs-wrapper">
    { component.state.searchOverlay && (
      <div class="docs-search-wrapper">
        <div class="docs-search-wrapper-bg" onclick={ e => component.closeSearch() }></div>
        <div class="wrapper">
          <input type="search" autofocus="true" onkeyup={ e => component.makeSearch(e) } onkeydown={ e => component.searchKey(e) } placeholder="Search.." model={ component.state.search }/>
          {/* <h2>Search results</h2> */}
          {
            component.state.results.length > 0
              ? <ul class="search-results">
                  { component.state.results.map(result => (
                        <li>
                          <Link to={ result.page }>
                            <strong>{ result.title }</strong>
                            <span html={ component.addHighlight(result.term) }></span>
                          </Link>
                        </li>
                      ))
                  }
                </ul>
              : <div>No results</div>
          }
        </div>
      </div>
    ) }
    {/* <div class="under-hero-repl">
      <MiniRepl code={ globals.startingCode } />
    </div> */}
    { this.children }
    <footer>
      <p>Edit this page on <a href="https://github.com/radi-js/radi/docs" target="_blank">Github</a></p>
    </footer>
  </div>
</template>];
      }

  };