import introduction from 'radi/docs/readme.md'
import installation from 'radi/docs/installation.md'
import hyperscript from 'radi/docs/hyperscript.md'
import components from 'radi/docs/components.md'
import state from 'radi/docs/state.md'
import actions from 'radi/docs/actions.md'
import view from 'radi/docs/view.md'
import listener from 'radi/docs/listener.md'
import events from 'radi/docs/events.md'
import headlessComponents from 'radi/docs/headless-components.md'
import plugin from 'radi/docs/plugin.md'
import mount from 'radi/docs/mount.md'

export default {
  introduction,
  installation,
  hyperscript,
  components,
  state,
  actions,
  view,
  listener,
  events,
  headlessComponents,
  plugin,
  mount
}
