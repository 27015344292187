export default function search(x,y) {
  var list = x.toLowerCase().replace(/  /g, ' ').replace(/[^a-zA-z\d]+/g, ' ').trim().split(' ')
  var y2 = y.toLowerCase()
  var score = 0
  var step = 100 / list.length / 2
  for (var i = 0; i < list.length; i++) {
    var strength = 2 - (2 / list.length * i)
    if (y2.indexOf(list[i]) >= 0) score += step * strength
    if ((new RegExp('(^|\\b)' + list[i] + '($|\\b)', 'g')).test(y2)) score += step * strength
  }
  return score
}
