import docs from './docs-bundle.js'

export default [
  {
    page: '/docs',
    title: 'Introduction',
    html: docs.introduction,
    p: docs.introduction.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/installation',
    title: 'Installation',
    html: docs.installation,
    p: docs.installation.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/hyperscript',
    title: 'Hyperscript',
    html: docs.hyperscript,
    p: docs.hyperscript.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/components',
    title: 'Components',
    html: docs.components,
    p: docs.components.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/state',
    title: 'State',
    html: docs.state,
    p: docs.state.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/actions',
    title: 'Actions',
    html: docs.actions,
    p: docs.actions.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/view',
    title: 'View',
    html: docs.view,
    p: docs.view.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/listener',
    title: 'Listener',
    html: docs.listener,
    p: docs.listener.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/events',
    title: 'Events',
    html: docs.events,
    p: docs.events.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/headless-components',
    title: 'Headless Components',
    html: docs.headlessComponents,
    p: docs.headlessComponents.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/plugin',
    title: 'Plugin',
    html: docs.plugin,
    p: docs.plugin.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
  {
    page: '/docs/mount',
    title: 'Mount',
    html: docs.mount,
    p: docs.mount.replace(/<\/?[^>]+(>|$)/g, '').split('. '),
  },
]
