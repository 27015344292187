// import logo from '../../assets/img/llwhite.png'
// import logoColor from '../../assets/img/llcolor.png'

export default {
  github: 'https://github.com/radi-js/radi',
  slack: 'https://join.slack.com/t/radijs/shared_invite/enQtMjk3NTE2NjYxMTI2LWFmMTM5NTgwZDI5NmFlYzMzYmMxZjBhMGY0MGM2MzY5NmExY2Y0ODBjNDNmYjYxZWYxMjEyNjJhNjA5OTJjNzQ',
  docs: '/docs',
  repl: '/fiddle',
  startingCode: `state: {
  count: 0
}

@action change(diff) {
  return {
    count: this.state.count + diff
  }
}

<template>
  <h2>{ this.state.count }</h2>

  <button
    class="btn"
    disabled={ this.state.count <= 0 }
    onclick={ () => -1 |> this.change }>
    -
  </button>

  <button
    class="btn"
    onclick={ () => 1 |> this.change }>
    +
  </button>
</template>`,
}
